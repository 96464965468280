<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div>
          <div class="columns">
            <div class="column">
              <div class="block" style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;">
                <span class="has-text-weight-bold">Questionnaire Properties</span>
              </div>
              <div class="block">
                <form
                  @submit.prevent="validateBeforeSubmit">
                  <div class="flex-container">
                    <div class="flex-field">
                      <label class="label">
                        Year
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <div class="select">
                          <select v-model="input.year">
                            <option v-for="opt in years" :key="opt" :value="opt">{{opt}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="flex-field">
                      <label class="label">
                        Institution
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <div class="select">
                          <select v-model="input.institution" v-on:change="setInstitution">
                            <option v-for="opt in institutions" :key="opt.code" :value="opt">{{opt.name }} - {{ opt.id }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="flex-field">
                      <label class="label">
                        Plan Type
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <div class="select">
                          <select v-model="input.planType" v-on:change="checkPlanType" disabled>
                            <option v-for="opt in planTypes" :key="opt.id" :value="input.planType" :selected="opt === input.planType">{{opt.typeCode}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="flex-field">
                      <label class="label">
                        Questionnaire Name
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="field">
                        <input type="text" v-model="name" :disabled="disableName" style="width: 420px;" />
                      </div>
                    </div>
                  </div>
                  <div class="block" style="padding: 14px 0px 7px 0px; border-bottom: solid 1px lightgray;">
                  </div>
                  <div v-if="showForm" style="margin-top: 20px; text-align: center;">
                    <div class="heading" style="font-size:20px;">{{ name }}</div>
                    <div class="subheading">
                      <b>Bank Contact Information</b>
                      <br />
                      Please review and correct the information as needed.
                    </div>
                    <div class="field">
                      <label class="label">
                        Legal Address
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control-center">
                        <textarea v-model="input.legalAddress" rows="5" cols="50" name="legal address" v-validate="'required'"></textarea>
                        <span v-show="errors.has('legal address')" class="help is-danger">{{ errors.first('legal address') }}</span>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">
                        Mailing Address
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control-center">
                        <textarea v-model="input.mailingAddress" rows="5" cols="50" name="mailing address" v-validate="'required'"></textarea>
                        <span v-show="errors.has('mailing address')" class="help is-danger">{{ errors.first('mailing address') }}</span>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">
                        Main Business Phone
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control-center">
                        <input v-model="input.phone" @keydown="forceFormat" @keyup="formatPhoneInput" name="business phone" v-validate="'required'"/>
                        <span v-show="errors.has('business phone')" class="help is-danger">{{ errors.first('business phone') }}</span>
                      </div>
                    </div>
                     <div class="field">
                      <label class="label">
                        Main Fax
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control-center">
                        <input v-model="input.fax" @keydown="forceFormat" @keyup="formatPhoneInput" name="fax number" v-validate="'required'"/>
                        <span v-show="errors.has('fax number')" class="help is-danger">{{ errors.first('fax number') }}</span>
                      </div>
                    </div>
                    <div class="subheading">
                      <b>Contact Information</b>
                      <br />
                      Please review and adjust entries as needed.
                    </div>
                    <div class="table" style="width: 100%;">
                      <DataTable
                        :header-fields="contactFields"
                        :tableData="contactTable || []"
                        :is-loading="isLoading"
                        :css="datatableCss"
                        not-found-msg="No contacts found">
                          <input
                            slot="actions"
                            slot-scope="props"
                            type="button"
                            class="btn btn-info"
                            value="-"
                            @click="removeRow(contactTable, props);"
                          >
                      </DataTable>
                      <br />
                    </div>
                    <div class="subheading">
                      <b>Plan Participant Basic Information</b>
                      <br />
                      Please review and adjust entries as needed.
                    </div>
                    <div class="table">
                      <DataTable
                        :header-fields="participantFields"
                        :tableData="participantTable || []"
                        :is-loading="isLoading"
                        :css="datatableCss"
                        not-found-msg="No participants found">
                          <input
                            slot="actions"
                            slot-scope="props"
                            type="button"
                            class="btn btn-info"
                            value="-"
                            @click="removeRow(participantTable, props);"
                          >
                      </DataTable>
                    </div>
                    <div class="block" style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;">
                      <span class="has-text-weight-bold">Questionnaire Notifications</span>
                    </div>
                    <div class="columns">
                      <div class="column flex-column">
                        <div class="block">
                          <span class="has-text-weight-bold">Assign Dates</span>
                        </div>
                        <div class="field">
                          <label class="label is-size-7">
                            Due Date
                            <span class="has-text-warning"
                                  style="padding-left: 3px;">*</span>
                          </label>
                          <kendo-datepicker id="dueDate"
                                            name="due date"
                                            v-validate="'required'"
                                            style="width: 200px"
                                            v-model="input.dueDate"
                                            :format="'yyyy-MM-dd'">
                          </kendo-datepicker>
                          <span v-show="errors.has('due date')" class="help is-danger">{{ errors.first('due date') }}</span>
                        </div>
                        <div class="field">
                          <label class="label is-size-7">
                            Info 'As Of' Date
                            <span class="has-text-warning"
                                  style="padding-left: 3px;">*</span>
                          </label>
                          <kendo-datepicker id="asOfDate"
                                            name="info date"
                                            v-validate="'required'"
                                            style="width: 200px"
                                            v-model="input.asOfDate"
                                            :format="'yyyy-MM-dd'">
                          </kendo-datepicker>
                          <span v-show="errors.has('info date')" class="help is-danger">{{ errors.first('info date') }}</span>
                        </div>
                        <div class="block" style="margin-top: 3rem">
                          <span class="has-text-weight-bold">Client Portal Notification Message</span>
                        </div>
                        <textarea v-model="input.stickyNote" rows="5" cols="50"></textarea>
                      </div>
                      <div class="column flex-column">
                        <div class="block">
                          <span class="has-text-weight-bold">Invite Clients</span>
                        </div>
                        <div class="invite-client-wrapper">
                            <InviteClients :model="this.invitationInput" :institutionId="this.input.institution.id" @invitationsUpdated="onInvitationsUpdated" />
                            <div v-for="(reminder, i) in invitationsTable" :key="i" class="flex-center" style="margin-top: 1rem; width: 100%;">
                                <div class="flex-field" style="padding: 0; width: 45%;">
                                    <label class="label is-size-7">
                                    Reminder Date/Time
                                    <span class="has-text-warning"
                                            style="padding-left: 3px;">*</span>
                                    </label>
                                    <kendo-datepicker id="reminderDate"
                                                    style="width: 100%"
                                                    v-model="reminder.reminderDate"
                                                    :format="'yyyy-MM-dd'">
                                    </kendo-datepicker>
                                    <input v-model="reminder.reminderDate" v-show="false" :name="`reminder date ${i}`" v-validate="'required'" />
                                    <span v-show="errors.has(`reminder date ${i}`)" class="help is-danger">{{ 'The reminder date field is required' }}</span>
                                </div>
                                <div class="flex-field" style="padding: 0; width: 45%;">
                                    <label class="label is-size-7">
                                    Email Template
                                    <span class="has-text-warning"
                                            style="padding-left: 3px;">*</span>
                                    </label>
                                    <div class="select" style="width: 100%;">
                                    <select v-model="reminder.emailTemplate" :name="`email template ${i}`" v-validate="'required'">
                                        <option v-for="opt in emailTemplates" :key="opt.id" :value="opt">{{opt.name}}</option>
                                    </select>
                                    <span v-show="errors.has(`email template ${i}`)" class="help is-danger">{{ 'The email template field is required' }}</span>
                                    </div>
                                </div>
                                    <div class="flex-field" style="position: absolute; right: -2.8rem; margin-top: 1.4rem;">
                                        <a class="button is-light" v-on:click="removeReminder(reminder)">-</a>
                                    </div>
                                </div>
                                <a class="button is-light" style="margin-left: auto; margin-top: .5rem;" v-on:click="addReminder">Add reminder</a>
                            </div>
                      </div>
                    </div> -->
                  </div>

                  <div id="buttongroup" style="padding-top: 20px;">
                    <div class="field is-grouped">
                      <div class="control">
                        <button type="submit" class="button is-accent has-text-white">Save</button>
                      </div>
                      <div class="control">
                        <a class="button is-light" v-on:click="resetForm();$router.go(-1)">Cancel</a>
                      </div>
                    </div>
                  </div>
                </form>
                <div id="plan-type-modal" class="modal is-active" v-if="showPlanTypeModal">
                  <div class="modal-background" @click="showPlanTypeModal = false"></div>
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <div class="modal-card-title">Add New Plan Type</div>
                      <a class="delete" aria-label="close" @click="showPlanTypeModal = false"></a>
                    </header>
                    <section class="modal-card-body">
                        <div class="content" v-if="planTypeExists">
                          <b>Plan Type '{{newPlanType.toUpperCase()}}' already exists.</b>  Please select it from the list or enter a new Plan Type.
                        </div>
                      <div class="field">
                        <div class="control">
                          <input type="text" class="input" v-model="newPlanType" placeholder="Enter a new Plan Type Code" v-focus />
                        </div>
                      </div>
                    </section>
                    <footer class="modal-card-foot">
                      <a class="button is-accent has-text-white" @click="addPlanType">
                        <span>Save Plan Type</span>
                      </a>
                      <a class="button" @click="showPlanTypeModal=false">Cancel</a>
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import DataTable from './templates/DataTable'
import Loading from './Loading'
import InviteClients from './EditQuestionnaireClients'
import { DatePicker } from '@progress/kendo-dateinputs-vue-wrapper'

let pageName = 'New Questionnaire'

export default {
  components: {
    Loading,
    DataTable,
    InviteClients,
    'kendo-datepicker': DatePicker
  },
  props: {
    model: {
      default: () => {
        return {
          id: 0,
          year: '',
          institution: '',
          planType: ''
        }
      }
    }
  },
  data () {
    return {
      input: {
        id: 0,
        year: '',
        institution: '',
        planType: '',
        dueDate: '',
        asOfDate: ''
      },
      id: 0,
      year: '',
      years: [],
      name: '',
      disableName: true,
      institution: {},
      institutions: [],
      planType: {},
      planTypes: [],
      emailTemplate: {},
      emailTemplates: [],
      isLoading: false,
      showPlanTypeModal: false,
      showForm: false,
      newPlanType: '',
      planTypeExists: false,
      pageheading: pageName,
      dueDate: null,
      asOfDate: null,
      legalAddress: '',
      mailingAddress: '',
      phone: '',
      fax: '',
      contactFields: [
        {
          name: 'name',
          label: 'Name',
          width: '25%',
          isEditable: true
        },
        {
          name: 'title',
          label: 'Title',
          width: '25%',
          isEditable: true
        },
        {
          name: 'phone',
          label: 'Direct Phone',
          format: 'phone',
          width: '25%',
          isEditable: true
        },
        {
          name: 'email',
          label: 'Email',
          width: '25%',
          isEditable: true
        },
        '__slot:actions'
      ],
      contactTable: [{}],
      participantFields: [
        {
          name: 'name',
          label: 'Name',
          isEditable: true
        },
        {
          name: 'title',
          label: 'Title',
          isEditable: true
        },
        {
          name: 'newTitle',
          label: 'New Title',
          isEditable: true
        },
        {
          name: 'terminationDate',
          label: 'Termination Date',
          format: 'date',
          isEditable: true
        },
        {
          name: 'terminationReason',
          label: 'Termination Reason',
          isEditable: true
        },
        '__slot:actions'
      ],
      participantTable: [{}],
      datatableCss: {
        table: 'table table-bordered table-hover table-striped table-center',
        th: 'header-item',
        thWrapper: 'th-wrapper',
        footer: 'footer'
      },
      invitationsInput: {
        users: []
      },
      invitationsTable: [
        {
          id: 0,
          reminderDate: '',
          emailTemplate: ''
        }
      ]
    }
  },
  watch: {
    input: {
      handler () {
        if (this.input.year !== '' && this.input.institution !== '') {
          this.name = `${this.input.year} Annual Questionnaire for ${this.input.institution.name} - ${this.input.institution.id}`
          this.disableName = false
          this.showForm = true
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState([activeSite]),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    isCreateMode () {
      let id = this.$route.params.id
      let isCreateMode = !id || id <= 0

      if (!isCreateMode) {
        pageName = 'Edit Questionnaire'
      }
      return isCreateMode
    }
  },
  async beforeRouteEnter (to, from, next) {
    let model = null
    next(async vm => {
      if (!vm.isCreateMode) {
        model = await vm.getQuestionnaire(to.params.id)
      }

      if (model) {
        vm.input.id = model.id
        vm.input.year = model.year
        vm.input.institution = model.institution
        vm.input.planType = model.planType
        vm.input.stickyNote = model.stickyNote
        vm.input.dueDate = model.dueDate
        vm.input.asOfDate = model.asOfDate
        vm.name = model.name

        if (model.responseData) {
          let responseData = JSON.parse(model.responseData)
          vm.legalAddress = responseData.legalAddress
          vm.mailingAddress = responseData.mailingAddress
          vm.phone = responseData.phone
          vm.fax = responseData.fax
          vm.contactTable = responseData.contacts
          vm.participantTable = responseData.participants
        }
      }
    })
  },
  async created () {
    await this.getYears()
    await this.getInstitutions()
    await this.getPlanTypes()
    await this.getEmailTemplates()
    // if external direct link, need to load the model stateCode
    if (!this.isCreateMode && (!this.model || this.model.id <= 0)) {
      this.showForm = true
      // await this.loadModel('/questionnaires/', null, false)
      // TODO: Parse JSON into objects to populate
    } else {
      await this.setupForm(this.model, false)
    }
  },
  methods: {
    async getYears () {
      try {
        let minYear = 2021 // 2021 is the first year when Annual Questionnaires were implemented
        const maxYear = new Date().getFullYear()
        for (; minYear <= maxYear; minYear++) {
          this.years.push(minYear)
        }
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getInstitutions () {
      try {
        let route = '/institutions/all/'
        let response = await this.axios.get(route)
        this.institutions = response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getPlanTypes () {
      try {
        let route = '/questionnaires/plan-types/'
        let response = await this.axios.get(route)
        this.planTypes = response.data
        this.input.planTypes = this.planTypes
        this.planType = this.planTypes[0]
        // TODO: Uncomment for Phase 2 - backend already complete
        // this.planTypes.push({ 'id': -1, 'typeCode': 'Add a new Plan Type' })
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getEmailTemplates () {
      try {
        let route = '/email-templates/'
        let response = await this.axios.get(route)
        this.emailTemplates = response.data.records.filter(r => r.emailTemplateTypeId === 2)
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getQuestionnaire (id) {
      try {
        this.isLoading = true

        let route = `/questionnaires/${id}`
        let response = await this.axios.get(route)
        this.input.invitations = response.data.invitations

        // TODO: map to array of schedules
        if (response.data.schedules && response.data.schedules[0] !== undefined) {
          const schedule = response.data.schedules[0]
          this.input.reminderTimestamp = schedule.reminderTimestamp
          this.input.sendImmediately = schedule.sendImmediately
          this.input.emailTemplateId = schedule.emailTemplateId
        }

        this.invitationInput = {
          assignedClients: response.data.invitations,
          institutionId: response.data.institution.id
        }

        return response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    checkPlanType () {
      if (this.planType === -1) {
        this.showPlanTypeModal = true
        this.planTypeExists = false
        this.newPlanType = ''
      }
    },
    setInstitution () {
      this.invitationInput = {
        institutionId: this.input.institution.id,
        assignedClients: []
      }
    },
    async addPlanType (event, data) {
      this.planTypes.forEach(x => {
        if (x.typeCode === this.newPlanType.toUpperCase()) {
          this.planTypeExists = true
        }
      })

      if (!this.planTypeExists) {
        try {
          let route = '/questionnaires/plan-types/'
          await this.axios.post(route, JSON.stringify(this.newPlanType),
            {
              headers: { 'Content-Type': 'application/json' }
            })

          this.getPlanTypes()

          this.successToast(`Successfully created Plan Type ${this.newPlanType}!`)
          this.showPlanTypeModal = false
        } catch (error) {
          this.handleApiErr(error)
        }
      }
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((bool) => {
        if (bool) {
          this.handleSubmitLocal('/questionnaire/', -1, false, false, `The Questionnaire ID '${this.id}' already exists. Please enter a unique ID.`)
        }

        // scroll to first validation error
        let errors = document.querySelectorAll('.help')
        const firstError = [...errors].find(el => el.style.display === '')
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    },
    async handleSubmitLocal (route, nav) {
      let responseData = {
        legalAddress: this.legalAddress,
        mailingAddress: this.mailingAddress,
        fax: this.fax,
        phone: this.phone,
        contacts: this.contactTable,
        participants: this.participantTable
      }

      this.input.responseData = JSON.stringify(responseData)
      this.input.name = this.name
      this.input.planTypes = [this.input.planTypes[0].id] /// TODO: Map to array ids once multiselect enabled
      this.input.schedules = [...this.invitationsTable]
      this.input.invitations = this.InviteClients

      await this.handleSubmit(route, -1)
    },
    addReminder () {
      const newReminder = {
        id: this.invitationsTable.length,
        reminderDate: '',
        emailTemplate: ''
      }
      this.invitationsTable.push(newReminder)
    },
    removeReminder (data) {
      if (this.invitationsTable.length > 1) {
        this.invitationsTable = this.invitationsTable.filter(row => row !== data)
      }
    },
    removeRow: function (table, row) {
      if (table.length > 1) {
        table.splice(row.rowIndex, 1)
      }
    },
    onInvitationsUpdated (event) {
      this.invitationInput = event
    },
    forceFormat (event) {
      // check if input value is a number value
      let isNumericInput
      if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
        isNumericInput = true
      } else {
        isNumericInput = false
      }

      // check if input value is a modifier key ex: arrowLeft, arrowRight, capslock, shift ect
      let isModifierKey
      if ((event.shiftKey === true || event.keyCode === 35 || event.keyCode === 36) || (event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 13 || event.keyCode === 46) || (event.keyCode > 36 && event.keyCode < 41) || ((event.ctrlKey === true || event.metaKey === true) && (event.keyCode === 65 || event.keyCode === 67 || event.keyCode === 86 || event.keyCode === 88 || event.keyCode === 90))) {
        isModifierKey = true
      } else {
        isModifierKey = false
      }

      // if inputed value is not a modifier or numeric strip it
      if (!isNumericInput && !isModifierKey) {
        event.preventDefault()
      }
    },
    formatPhoneInput (event) {
      // check if input value is a modifier key ex: arrowLeft, arrowRight, capslock, shift ect
      let isModifierKey
      if ((event.shiftKey === true || event.keyCode === 35 || event.keyCode === 36) || (event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 13 || event.keyCode === 46) || (event.keyCode > 36 && event.keyCode < 41) || ((event.ctrlKey === true || event.metaKey === true) && (event.keyCode === 65 || event.keyCode === 67 || event.keyCode === 86 || event.keyCode === 88 || event.keyCode === 90))) {
        isModifierKey = true
      } else {
        isModifierKey = false
      }

      // dont change anything if modifier key inputed
      // eslint-disable-next-line block-spacing
      if (isModifierKey) {return}

      // get the input element and auto format value to (000) 000-0000
      const el = event.target
      const userInput = el.value.replace(/\D/g, '').substring(0, 10)
      const zip = userInput.substring(0, 3)
      const middle = userInput.substring(3, 6)
      const last = userInput.substring(6, 10)

      if (userInput.length > 6) {
        el.value = `(${zip}) ${middle}-${last}`
      } else if (userInput.length > 3) {
        el.value = `(${zip}) ${middle}`
      } else if (userInput.length > 0) {
        el.value = `(${zip}`
      }
    }
  }
}
</script>

<style>
  #body-content-area {
    position: absolute;
    top: 35px;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
  }

  #pagelayout {
    right: 100%;
    bottom: 0;
  }

  .subheading {
    font-size: 1em;
    padding: 30px 0 10px 0;
  }

  div.flex-field {
    display: inline-block;
  }

  div.flex-field:not(:first-child) {
    padding-left: 20px;
  }

  .field {
    color: #4a4a4a;
  }

  .flex-column {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
  }

  .flex-container {
    display: flex;
    align-items: center;
  }

  .flex-center {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .field input, .field textarea, .flex-field input, .column input, .column textarea {
    font-size: 1em;
    background-color: white;
    border-color: #dbdbdb;
    border-radius: 4px;
    box-shadow: none;
    border-width: 1px;
    border-style: solid;
    min-height: 36px;
    color: #363636;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
  }

/* Datatable CSS */
.v-datatable-light {
  display: inline-block;
  margin: 10px 0;
}
/* End Datatable CSS */

/* Invite Client CSS*/
.invite-client-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-right: 2.8rem;
}

.invite-client-wrapper .control > .select {
  display: flex;
  height: 16rem;
}
/* End Invite Client CSS */
</style>
